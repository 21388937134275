// Loading screen while app initialization
@keyframes app-init-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// Shake animation for bell notification icon
@keyframes bell-shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(12deg) scale(1.1);
  }
  20% {
    transform: rotate(-7deg) scale(1.2);
  }
  30% {
    transform: rotate(7deg) scale(1.1);
  }
  40% {
    transform: rotate(0deg);
  }
}
