@use "keyframe-animations.scss";

:root {
  --bucher-red: #DC0023;
  --bucher-silver-grey: #EBEBEB;
  --bucher-dark-silver-grey: darken(#EBEBEB, 20%);
  --bucher-dark-green: #466400;
  --bucher-mid-green: #5A9114;
  --bucher-light-green: #8CBE0F;
  --bucher-yellow: #FCC100;
  --bucher-dark-blue: #00557A;
  --bucher-mid-blue: #0087C8;
  --bucher-light-blue: #00AFF0;

  --margin-s: 30px;
  --margin-xxs: 8px;

  --font-normal: 400;
  --font-bold: 600;
  --font-bolder: 800;

  --h1-font-size: 23px;
  --h2-font-size: 21px;
  --h3-font-size: 19px;

  --h1-line-height: 31px;
  --h2-line-height: 27px;
  --h3-line-height: 23px;

  // ci break points
  //--screen-width-s: 360px;
  //--screen-width-m: 768px;
  //--screen-width-l: 1024px;
  //--screen-width-xl: 1365px;
  //--screen-width-max: 1920px;

  --margin-xs: 20px;
  --margin-m: 60px;
  --margin-l: 90px;
  --margin-xl: 110px;

  @media only screen and (max-width: 1364px) {
    --margin-xs: 16px;
    --margin-m: 40px;
    --margin-l: 60px;
    --margin-xl: 90px;
  }
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: var(--font-normal);
}

// Excluding header elements inside the quill editor elements
:not(quill-editor div) > {
  h1 {
    margin: 0 !important;
    color: var(--bucher-red) !important;

    // CI h5
    font-size: var(--h1-font-size) !important;
    line-height: var(--h1-line-height) !important;
  }

  h2 {
    padding: 0 !important;
    margin: 0 !important;
    color: var(--bucher-red) !important;

    // CI h6
    font-size: var(--h2-font-size) !important;
    line-height: var(--h2-line-height) !important;
  }

  h3 {
    padding: 0 !important;
    margin: 0 !important;
    color: var(--bucher-red) !important;

    font-size: var(--h3-font-size) !important;
    line-height: var(--h3-line-height) !important;
  }
}

.mt-xxs {
  margin-top: var(--margin-xxs) !important;
}

.mt-xs {
  margin-top: var(--margin-xs) !important;
}

.mt-s {
  margin-top: var(--margin-s) !important;
}

.mt-m {
  margin-top: var(--margin-m) !important;
}

.mt-l {
  margin-top: var(--margin-l) !important;
}

.mt-xl {
  margin-top: var(--margin-xl) !important;
}

.mb-xxs {
  margin-bottom: var(--margin-xxs) !important;
}

.mb-xs {
  margin-bottom: var(--margin-xs) !important;
}

.mb-s {
  margin-bottom: var(--margin-s) !important;
}

.mb-m {
  margin-bottom: var(--margin-m) !important;
}

.mb-l {
  margin-bottom: var(--margin-l) !important;
}

.mb-xl {
  margin-bottom: var(--margin-xl) !important;
}

.ml-xxs {
  margin-left: var(--margin-xxs) !important;
}

.ml-xs {
  margin-left: var(--margin-xs) !important;
}

.mr-xxs {
  margin-right: var(--margin-xxs) !important;
}

.mr-xs {
  margin-right: var(--margin-xs) !important;
}

.pl-xxs {
  padding-left: var(--margin-xxs) !important;
}

.pl-xs {
  padding-left: var(--margin-xs) !important;
}

.pl-s {
  padding-left: var(--margin-s) !important;
}

.pr-xxs {
  padding-right: var(--margin-xxs) !important;
}

.pr-xs {
  padding-right: var(--margin-xs) !important;
}

.p-xxs {
  padding: var(--margin-xxs) !important;
}

.p-xs {
  padding: var(--margin-xs) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.table-wrapper {
  overflow: auto;
}

.card-title {
  display: flex;
  align-items: center;

  mat-icon {
    font-size: 30px;
    width: 30px;
    height: 30px;
    margin-right: 1rem;
    color: var(--bucher-red);
  }
}

.action-column {
  white-space: nowrap;
  width: 1px;
  text-align: center !important;

  &.document-action-buttons {
    text-align: right !important;
  }
}

.fitting-column {
  white-space: nowrap;
  width: 1px;
}

.table {
  border-collapse: collapse;

  td, th {
    font-size: 14px;
    line-height: 21px !important;
  }

  th {
    font-weight: var(--font-bold) !important;
  }
}

.admin-management-page {
  width: 100%;

  @media print {
    button, mat-divider, .action-column, mat-card-footer, .admin-management-form, bucher-button {
      display: none;
    }

    mat-card {
      box-shadow: none;
      border: 0;

      mat-card-content, mat-card-header {
        padding: 0;
      }

      mat-card-title {
        line-height: 15px;
        font-size: 15px;

        mat-icon {
          display: none;
        }
      }
    }

    td, th {
      padding: 5px;
      font-size: 12px;
    }

    tr {
      height: auto;
    }
  }

  mat-card-header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .admin-management-form {
    mat-form-field {
      width: 100%;
    }
  }

  .admin-management-table {
    overflow: auto;

    th {
      white-space: nowrap;
    }

    .short-column {
      white-space: nowrap;
    }

    table, th, td {
      border: 1px solid #e0e0e0;
    }

    th, td {
      max-width: 400px;
    }

    .text-column {
      min-width: 200px;
    }

    .mat-mdc-no-data-row td {
      padding: 1rem;
    }
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .card-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin-top: calc(var(--margin-xxs) * -1);
    margin-left: calc(var(--margin-xxs) * -1);

    button, a, bucher-button {
      margin-top: var(--margin-xxs);
      margin-left: var(--margin-xxs);
    }
  }
}

.admin-form {
  mat-form-field {
    width: 100%;
  }
}

.admin-view-page {
  mat-card-header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .view-item-actions {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-top: calc(var(--margin-xxs) * -1);
      margin-left: calc(var(--margin-xxs) * -1);

      .view-item-action {
        margin-top: var(--margin-xxs);
        margin-left: var(--margin-xxs);
      }
    }
  }

  .item-properties {
    display: flex;
    flex-wrap: wrap;
  }

  .item-property {
    margin-top: 1rem;
    overflow-wrap: anywhere;
    display: flex;
    min-width: 30vw;

    @media only screen and (max-width: 1023px) {
      width: 100%;
      padding-right: 0;
      flex-direction: column;
    }

    @media only screen and (min-width: 1024px) {
      .item-property-title {
        width: 15rem;
        min-width: 15rem;
        max-width: 15rem;
      }

      .item-property-value {
        padding-right: 2rem;
      }
    }

    @media only screen and (min-width: 1024px) and (max-width: 1919px) {
      width: 50%;
    }

    @media only screen and (min-width: 1920px) {
      width: 40%;
    }

    &:last-child {
      margin-bottom: 1rem;
    }

    .item-property-title {
      font-size: 14px;
      font-weight: var(--font-bold);
      padding-right: 1rem;
    }
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.admin-form-page {
  display: flex;
  justify-content: center;

  .form-wrapper {
    mat-form-field {
      width: 100%;
    }

    mat-card-header {
      margin-bottom: 1rem;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) {
      min-width: 500px;
      max-width: 992px;
    }
  }
}

.form-actions {
  width: 100%;
  display: flex;

  button, a, bucher-button {
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;

    .form-action {
      width: 100%;
      margin-top: 1rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .form-action {
      width: 50%;
      margin-left: .5rem;
      margin-right: .5rem;

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

.form-field-row {
  @media only screen and (min-width: 768px) {
    display: flex;

    .form-field-col {
      width: calc(50% - .25rem);
      margin-left: .125rem;
      margin-right: .125rem;

      &:first-child {
        margin-right: .25rem;
        margin-left: 0;
      }

      &:last-child {
        margin-left: .25rem;
        margin-right: 0;
      }
    }

    .form-field-col-3 {
      width: calc(100% / 3 - .25rem);
      margin-left: .125rem;
      margin-right: .125rem;

      &:first-child {
        margin-right: .25rem;
        margin-left: 0;
      }

      &:last-child {
        margin-left: .25rem;
        margin-right: 0;
      }
    }

    .form-field-col-4 {
      margin-left: .125rem;
      margin-right: .125rem;

      &:first-child {
        margin-right: .25rem;
        margin-left: 0;
      }

      &:last-child {
        margin-left: .25rem;
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .form-field-col-4 {
      width: 100%;
      margin-top: var(--margin-xs);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    flex-wrap: wrap;

    .form-field-col-4 {
      width: calc(100% / 2 - .25rem);
      margin-top: var(--margin-xs);

      &:nth-child(-n+2) {
        margin-top: 0;
      }

      &:nth-child(odd) {
        margin-right: .25rem;
        margin-left: 0;
      }

      &:nth-child(even) {
        margin-left: .25rem;
        margin-right: 0;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .form-field-col-4 {
      width: calc(100% / 4 - .25rem);
    }
  }
}

.dialog-actions {
  padding: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 52px;
  margin-top: calc(var(--margin-xxs) * -1);
  margin-left: calc(var(--margin-xxs) * -1);

  bucher-button {
    margin-top: var(--margin-xxs);
    margin-left: var(--margin-xxs);
  }
}

.action-buttons {
  margin-top: calc(var(--margin-xxs) * -1);
  margin-left: calc(var(--margin-xxs) * -1);

  button, a, bucher-button {
    margin-top: var(--margin-xxs);
    margin-left: var(--margin-xxs);
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--margin-xxs) * -1);
  margin-left: calc(var(--margin-xxs) * -1);

  button, a, bucher-button {
    margin-top: var(--margin-xxs);
    margin-left: var(--margin-xxs);
  }
}

.link {
  color: black;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.no-print {
  @media print {
    display: none !important;
  }
}

.no-mobile {
  @media only screen and (max-width: 1023px) {
    display: none !important;
  }
}

bucher-button {
  mat-icon {
    margin-right: 5px;
  }
}

.mdc-dialog__title {
  text-indent: -8px;
}

.fw-bold {
  font-weight: var(--font-bold);
}

ul {
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 1.2em;

    &:before {
      content: "-";
      position: absolute;
      left: 0;
    }
  }
}

.input-error {
  color: #DE0E08;
  font-size: 12px;
}

.default-sidebar {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media only screen and (max-width: 1023px) {
    border-radius: 0;
    box-shadow: none;
  }

  .default-sidebar-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: .7rem 1rem;
  }

  .default-sidebar-item-group {
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }

    a {
      color: black;
      text-decoration: none;
    }

    .default-sidebar-item-group-title {
      padding: 1rem;
      font-size: 1.15rem;
      font-weight: var(--font-bold);
      border-bottom: 1px solid white;
    }

    .default-sidebar-item {
      padding: 1rem 1rem 1rem 1.5rem;
      font-size: 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      mat-icon {
        min-width: 24px;
        margin-right: 1rem;
      }

      &:hover {
        background-color: whitesmoke;
      }
    }
  }

  .active-sidebar-route {
    border-bottom: 2px solid #DC0023;
    background-color: whitesmoke;
  }

  .mat-expansion-panel {
    box-shadow: none !important;
  }

  .master-data {
    .master-data-title {
      cursor: pointer;
      font-size: 1rem;
      display: flex;
      align-items: center;
      font-weight: var(--font-normal);

      mat-icon {
        margin-right: 1rem;
      }
    }

    .default-sidebar-sub-items {
      display: flex;
      flex-direction: column;

      .default-sidebar-sub-item {
        width: 100%;
        padding: 1rem 1rem 1rem 3rem;
      }
    }
  }

  .bottom-group {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .notification-indicator {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      box-sizing: border-box;
      background-color: var(--bucher-red);
      color: white;
      line-height: 1.25rem;
      min-width: 1.25rem;
      height: 1.25rem;
      border-radius: 25px;
      font-size: 0.8rem;
      text-align: center;
      padding-inline: 5px;
    }
  }
}

.long-tooltip {
  white-space: pre-line;
}
